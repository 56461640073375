<template>
  <div class="subid-report-filters">
    <slot />
    <div v-if="hasFilterGroups" class="subid-report-controls sm:tw-bottom-0 sm:tw-right-space-4 tw-flex tw-flex-col tw-text-center">
      <app-button
        class="filter-btn tw-mb-space-4 tw-bg-jb-indigo sm:tw-ml-space-4"
        :disabled="loading"
        :loading="loading"
        @click="handleClickApplyFilters">
        {{ btnText }}
      </app-button>
      <transition name="el-fade-in" mode="out-in">
        <app-link class="clear-btn !tw-text-jb-grey-700 tw-font-medium tw-text-sm" @click="handleClickClearFilters">
          Clear Filters
        </app-link>
      </transition>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/AppButton';
import AppLink from '@/components/AppLink';

export default {
  name: 'SubidReportFilters',
  components: {
    AppButton, AppLink
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    btnText: {
      type: String,
      default: 'Filter Report'
    },
    hasFilterGroups: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClickApplyFilters() {
      this.$emit('apply-filters');
    },
    handleClickClearFilters() {
      this.$emit('clear-filters');
    }
  }
};
</script>
