<template>
  <div class="campaign-affiliate-reporting">
    <section class="sm:tw-mt-0">
      <div v-if="reportType === 'subid'">
        <app-heading level="h3" weight="semi-bold" class="tw-text-jb-ink">
          SubId Reports
        </app-heading>
      </div>
    </section>
    <section v-if="reportType === 'subid'" class="filter-options sm:tw-mx-0 xsm:tw-my-0">
      <subid-report-filters
        key="app-left-sidebar-extension--campaign-affiliate-reporting"
        :has-filter-groups="(filterGroups.length >=1 || filterCampaignIds.length >= 1) && reportType === 'subid'"
        class="tw-mb-space-4 tw-bg-jb-indigo-lighter tw-p-space-2 sm:tw-p-space-4 2xl:tw-p-layout-4 sm:tw-flex sm:tw-justify-between sm:tw-items-center sm:tw-pb-layout-3 xsm:tw-px-layout-3 tw-mx-auto tw-rounded"
        @apply-filters="handleApplyReportOptions"
        @clear-filters="handleClearReportOptions">
        <div class="dropdown-cont sm:tw-flex">
          <div class="tw-mb-space-4 dropdown-item sm:tw-mr-layout-3">
            <app-heading level="h5" weight="medium" class="tw-text-jb-grey-700 tw-mb-space-4px tw-text-xs">
              Campaigns
            </app-heading>
            <dropdown-filter
              v-model="filterCampaignIds"
              :data="campaigns"
              value-key="id"
              filterable="false"
              :clearable="true"
              :remote="false"
              :multiple="true"
              :append-to-body="true"
              :collapse-after="3" />
          </div>
          <div class="tw-mb-space-4 dropdown-item">
            <app-heading level="h5" weight="medium" class="tw-text-jb-grey-700 tw-mb-space-4px tw-text-xs">
              Group By
            </app-heading>
            <dropdown-filter
              v-model="filterGroups"
              :data="computedSubids"
              :min="1"
              :clearable="true"
              :remote="false"
              :multiple="true"
              :append-to-body="true"
              :collapse-after="3" />
          </div>
        </div>
      </subid-report-filters>
    </section>
    <section class="tw-flex tw-items-center tw-justify-between">
      <div class="tw-mb-space-4 date-picker-cont">
        <app-heading level="h5" weight="medium" class="tw-text-jb-grey-700 tw-mb-space-4px tw-text-xs">
          Timeline
        </app-heading>
        <date-picker-filter :value="dateOptions" class="tw-w-layout-6" @input="(value) => dateOptions = value" />
      </div>
      <affiliate-reporting-export-button
        :disabled="!reportData.length"
        :loading="loadingCsvFile"
        class="campaign-affiliate-reporting__filters-btn "
        @download-csv="downloadReport" />
    </section>

    <div class="campaign-affiliate-reporting__inner">
      <affiliate-reporting-table
        :key="`affilaite-report-table-${reportType}`"
        :table-data="reportData"
        :table-summary="reportSummary"
        :pagination="pagination"
        :columns="reportColumns"
        :default-sort="sortState"
        :sticky-app-header-height="60"
        :loading="fetchingReport"
        :skeleton-rows="5"
        @on-sort="handleSort"
        @cell-click="handleCellClick"
        @page-change="handlePageChange">
        <el-table-column
          v-for="(column, index) in reportColumns"
          :key="column"
          :sort-orders="sortOrders"
          :prop="column"
          :label="columnWhiteListMap[column].label"
          :min-width="columnWhiteListMap[column].minWidth"
          :align="columnWhiteListMap[column].align"
          :class-name="columnClassName"
          sortable="custom">
          <template slot-scope="scope">
            <skeleton-box
              v-if="fetchingReport"
              :width="`${columnWhiteListMap[column].minWidth * 0.7}px`"
              :height="'12px'"
              :style="{ float: columnWhiteListMap[column].align || 'left' }" />
            <template v-else>
              <span :class="reportTableColumnClass(column)">
                <i v-if="index === 0" class="el-icon-arrow-right" />
                {{ getFormattedColumn(scope.row, column) }}
              </span>
            </template>
          </template>
        </el-table-column>
      </affiliate-reporting-table>
    </div>
    <portal v-if="rightSidebarVisible" to="app-right-sidebar">
      <router-view />
    </portal>
  </div>
</template>

<script>
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import { TableColumn } from 'element-ui';
import AffiliateReportingTable from '@/components/tables/AffiliateReportingTable';
import AffiliateReportMixin from '@/mixins/AffiliateReportMixin';
import SkeletonBox from '@/components/skeletons/SkeletonBox';
import DatePickerFilter from '@/components/filters/DatePickerFilter';
import AppLink from '@/components/AppLink';
import AppHeading from '@/components/AppHeading';
import AffiliateReportingExportButton from '@/components/affiliate/reporting/AffiliateReportingExportButton';
import ChevronPreviousIcon from '@/assets/svg/chevron-previous-icon.svg';
import SubidReportFilters from '@/components/filters/SubidReportFilters';
import DropdownFilter from '@/components/filters/DropdownFilter';

export default {
  name: 'CampaignAffiliateReporting',
  components: {
    DatePickerFilter,
    SubidReportFilters,
    ChevronPreviousIcon,
    AppLink,
    AppHeading,
    AffiliateReportingTable,
    AffiliateReportingExportButton,
    'el-table-column': TableColumn,
    SkeletonBox,
    DropdownFilter

  },
  mixins: [AffiliateReportMixin],
  props: {
    sortOrders: {
      type: Array,
      default: () => ['descending', 'ascending', null]
    }
  },
  data() {
    return {
      reportTitles: { mtd: 'Campaign', subid: 'Sub ID' },
      reportSubType: undefined,
      campaigns: [],
      isLoadingCampaigns: false,
      excludedColumns: Object.freeze([])
    };
  },
  computed: {
    rightSidebarVisible() {
      return this.$store.getters.rightSidebarVisible;
    },
    computedSubids() {
      return Object.entries(this.subids).map(([id, name]) => ({ id, name }));
    }
  },
  watch: {
    dateOptions() {
      this.handleApplyReportOptions();
    },
    rightSidebarVisible(visible) {
      if (!visible) {
        this.$nextTick(() => {
          this.updateRoute({
            ...this.$store.getters.affiliateReportOptions(this.reportType).report,
            reportSubType: undefined
          });
        });
      }
    }
  },
  mounted() {
    this.getCampaigns();
  },
  methods: {
    handleCellClick(row, column, cell, event) {
      const subReportOptions = {};
      let reportSubType = 'daily';
      if (['clicks', 'sales'].includes(column.property)) {
        reportSubType = column.property;
      } else {
        subReportOptions.groupBy = 'date';
      }

      const routeOptions = this.getActiveRouteOptions();
      const filter = _get(routeOptions, 'groupBy', '')
        .split(',')
        .reduce((o, key) => {
          o[key] = row[key];
          return o;
        }, {});

      this.updateRoute({
        reportSubType: reportSubType,
        filter: {
          ...routeOptions.filter,
          ...filter
        },
        ..._pick(routeOptions, ['dateRange', 'startDate', 'endDate']),
        ...subReportOptions
      });
    },
    getCampaigns(searchString = '') {
      this.isLoadingCampaigns = true;
      this.$api
        .campaigns.all({
          orgId: this.orgId,
          q: searchString,
          options: {
            page: 1
          },
          cancellationKey: `campaigns-${this.orgId}-${searchString}`
        })
        .then(result => {
          this.campaigns = result.data;
        })
        .finally(() => {
          this.isLoadingCampaigns = false;
        });
    },
    handleClickToggleFilters() {
      this.$store.dispatch('setLeftSidebarVisibility', true);
    }
  }
};
</script>

<style lang="scss">
.campaign-affiliate-reporting {
  @include page-layout;

  .filter-options {
    @media (min-width: $--sm) {
      max-width: 100%;

      .dropdown-cont {
        width: 75%;
        justify-content: flex-start;
      }

      .subid-report-controls {
        width: 25%;
        transform: translateY(18px);
      }

      .dropdown-item {
        width: 45%;
      }
    }

    @media (min-width: 1300px) {
      .dropdown-cont {
        width: 60%;
      }

      .subid-report-controls {
        width: 40%;
        flex-direction: row-reverse !important;
        align-items: baseline;
        transform: translateY(12px);
      }
    }
  }

  .affiliate-reporting-table {
    .el-table__body-wrapper {
      td.table-column-link {
        .cell {
          color: $--clb-color-primary;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  .subid-report-filters {
    min-height: 126px;

    .el-input__inner {
      height: 36px !important;
    }
  }

  .date-picker-filter {
    transform: scale(0.9) translateX(-14px);

    @media (min-width: 330px) {
      transform: scale(1) translateX(0);
    }
  }
}
</style>
